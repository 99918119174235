import Link from 'next-translate-routes-multi-domain/link';
import { useState } from 'react';
import { FaFilter } from 'react-icons/fa';

import {
  getProductSlug,
  isProductAvailable,
} from '@/lib/data-transformer/productData';
import { ApiProductsResponse, IProductListItem } from '@/lib/types/api';

import Drawer from '@/components/ui/Modals/Drawer/Drawer';
import ProductFilters from '@/components/forms/ProductFilters';
import { isEmpty, range } from 'lodash-es';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import {
  currencyFormat,
  numberFormat,
} from '@/lib/data-transformer/gindumacUtils';
import classNames from 'classnames';
import { AutocompleteSearchBar } from '@/components/forms/Filters/Searchbar';
import { useAppDispatch } from '@/redux/hooks';
import { IOrderby, setOrderby } from '../forms/Filters/filterSlice';
import useProducts from '@/lib/hooks/useProducts';
import { Pagination } from '../forms/Filters/Pagination';
import WantedRequest from '../forms/WantedRequest';
import React from 'react';
import { useRouter } from 'next-translate-routes-multi-domain';
import useTranslate from '@/lib/hooks/useTranslate';

function Products() {
  const {
    apiProductsResponse: productsResponse,
    isLoading,
    apiRelatedProductsResponse,
  } = useProducts();

  const hasRelatedProducts =
    !isLoading && isEmpty(productsResponse?.data?.products);
  const relatedProducts = apiRelatedProductsResponse?.data?.products ?? [];
  const { t } = useTranslate();
  return (
    <div
      className='grid grid-cols-1 gap-4 pt-4 md:grid-cols-2 xl:grid-cols-4'
      data-testid='product-list'
    >
      {isLoading && range(10).map((i) => <ProductCardSkeleton key={i} />)}
      {!isLoading &&
        productsResponse?.data?.products.map((product, index) => (
          <React.Fragment key={`fg-${product.id}`}>
            <ProductListItem key={product.id} p={product} />
            {index !== 0 && (index + 1) % 8 === 0 && (
              <WantedRequest key={product.id + 'wanted-request'} />
            )}
          </React.Fragment>
        ))}
      {!isLoading && isEmpty(productsResponse?.data?.products) && (
        <div className='col-span-full w-full'>
          {t('products:NO_PRODUCTS_FOUND')}
        </div>
      )}
      {/* related products */}
      {hasRelatedProducts && (
        <>
          <WantedRequest />
          <h1 className='col-span-full w-full text-center'>
            {t('products:RELATED_PRODUCT_TITLE')}
          </h1>
          {relatedProducts.map((product, index) => (
            <React.Fragment key={`fg-${product.id}`}>
              <ProductListItem key={product.id} p={product} />
              {index !== 0 && (index + 1) % 8 === 0 && (
                <WantedRequest key={product.id + 'wanted-request'} />
              )}
            </React.Fragment>
          ))}
        </>
      )}
    </div>
  );
}

export default function ProductsList() {
  const { apiProductsResponse: productsResponse } = useProducts();
  return (
    <main>
      <section className='flex w-full flex-col justify-center space-y-4 bg-white'>
        <div className='flex p-4'>
          <div className='float-left hidden min-w-[20em] p-4 lg:block'>
            <ProductFilters filters={productsResponse?.data.filters} />
          </div>
          <div className='flex-1 md:p-4'>
            <div className='flex w-full flex-col space-y-2 xl:flex-row xl:space-x-4 xl:space-y-0'>
              {/* TODO: render those component even when productsResponse is loading GP-4613 */}
              {productsResponse && (
                <div className='flex flex-1 items-center space-x-4'>
                  <SearchBar productsResponse={productsResponse} />
                </div>
              )}
              <div className={'flex justify-end'}>
                <Orderby />
                {productsResponse && (
                  <MobileFilters productsResponse={productsResponse} />
                )}
              </div>
            </div>
            <Products />
            <Pagination />
          </div>
        </div>
      </section>
    </main>
  );
}
function ProductCardSkeleton() {
  return (
    <div className='w-full'>
      <div className='h-full animate-pulse rounded-lg border bg-white shadow-lg'>
        <div className='flex-1 space-y-4 px-2 py-1'>
          <div className='h-48 w-full rounded bg-gray-400 ' />
          <div className='h-4 rounded bg-gray-400' />
          <div className='h-4 w-5/6 rounded bg-gray-400' />
        </div>
      </div>
    </div>
  );
}

function Orderby() {
  const { t } = useTranslation('products');
  const options: {
    slug: IOrderby;
    selected: boolean;
    value: string;
    text: string;
  }[] = [
    {
      selected: false,
      slug: 'recent_added_first',
      value: 'recent_added_first',
      text: t('products:PRODUCT_LIST_FILTERS_ORDERBY'),
    },
    {
      selected: false,
      slug: 'price_lowest_first',
      value: t('products:SORT_BY_PRICE_LOWEST_FIRST'),
      text: t('products:PRODUCT_LIST_FILTERS_ORDER_LOWEST'),
    },
    {
      selected: false,
      slug: 'price_highest_first',
      value: t('products:SORT_BY_PRICE_HIGHEST_FIRST'),
      text: t('products:PRODUCT_LIST_FILTERS_ORDER_HIGHEST'),
    },
    {
      selected: false,
      slug: 'year_newest_first',
      value: t('products:SORT_BY_YEAR_NEWEST_FIRST'),
      text: t('products:PRODUCT_LIST_FILTERS_ORDER_NEWEST'),
    },
    {
      selected: false,
      slug: 'year_oldest_first',
      value: t('products:SORT_BY_YEAR_OLDEST_FIRST'),
      text: t('products:PRODUCT_LIST_FILTERS_ORDER_OLDEST'),
    },
    {
      selected: false,
      slug: 'production_hours_highest_first',
      value: t('products:SORT_BY_PRODUCTION_HOURS_HIGHEST_FIRST'),
      text: t('products:PRODUCT_LIST_FILTERS_ORDER_MAXHOURS'),
    },
    {
      selected: false,
      slug: 'production_hours_lowest_first',
      value: t('products:SORT_BY_PRODUCTION_HOURS_LOWEST_FIRST'),
      text: t('products:PRODUCT_LIST_FILTERS_ORDER_MINHOURS'),
    },
  ];
  const dispatch = useAppDispatch();
  function handleOnChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const value: IOrderby = e.target.value as IOrderby;
    dispatch(setOrderby(value));
  }
  return (
    <>
      <>
        <label htmlFor='orderby' className='sr-only'>
          Select an option
        </label>
        <select
          id='orderby'
          className='block flex-1 rounded border border-gray-300 bg-gray-50 text-sm uppercase text-[#8C8294] focus:border-blue-500 focus:ring-blue-500 md:max-w-xs'
          onChange={handleOnChange}
        >
          {options.map((option) => (
            <option key={option.slug} value={option.slug} className='uppercase'>
              {option.text}
            </option>
          ))}
        </select>
      </>
    </>
  );
}

function MobileFilters({
  productsResponse,
}: {
  productsResponse: ApiProductsResponse;
}) {
  const [isDrawerFiltersOpen, setIsDrawerFiltersOpen] = useState(false);
  return (
    <div className='ml-4 block lg:hidden'>
      <button
        className='flex h-10 w-10 items-center justify-center rounded-full bg-brand-500 shadow-lg'
        onClick={() => setIsDrawerFiltersOpen(!isDrawerFiltersOpen)}
        type='button'
      >
        <FaFilter className='text-xl text-white' />
      </button>

      <Drawer
        isOpen={isDrawerFiltersOpen}
        setIsOpen={setIsDrawerFiltersOpen}
        size='4/5'
      >
        <ProductFilters filters={productsResponse?.data?.filters} />
      </Drawer>
    </div>
  );
}

export function ProductListItem({ p }: { p: IProductListItem }) {
  const { t } = useTranslation('products');
  const isAvailable = isProductAvailable(p);
  const { locale } = useRouter();
  return (
    <Link
      href={{
        pathname: '/product/[machine_id]',
        query: { machine_id: getProductSlug(p) },
      }}
    >
      <a
        className='group box-border h-full w-full cursor-pointer'
        data-testid='product-list-item'
      >
        <div className='relative mx-auto h-full border-2 border-neutral-200 transition duration-300 hover:-translate-y-2 hover:shadow-xl lg:max-w-lg'>
          <div className='aspect-h-3 aspect-w-4 w-full overflow-hidden bg-gray-200'>
            {/* tailwind nextjs image */}
            <Image
              src={p.thumbnail.thumbnail_url}
              alt={p.thumbnail.alt}
              layout='fill' // required
              objectFit='cover'
              className={isAvailable ? '' : 'brightness-50'}
            />
            {!isAvailable && (
              <h3 className='absolute left-1/2 top-1/2 m-0 h-10 w-min -translate-x-1/2 -translate-y-1/2 rotate-[-30deg] p-0 text-4xl font-medium uppercase text-white'>
                {t('products:Sold')}
              </h3>
            )}
          </div>
          <div className='space-y-2 px-4 py-6'>
            <h3 className='h2 truncate text-[1.1rem] font-medium uppercase text-brand-500 '>
              {p.model}
            </h3>
            <p className='h3 truncate text-sm uppercase text-brand-500'>
              {p.brand.name +
                ' - ' +
                p.machine_types.map((m) => ` ${t(m.name)}`)}
            </p>
            <div className='flex space-x-4 text-xs'>
              <div className='border-r-2 pr-4 uppercase text-muted-500'>
                {t(
                  'products:' + p.country.name,
                  {},
                  {
                    fallback: p.country.name,
                  }
                )}
              </div>
              <div
                className={classNames('text-muted-500', {
                  'border-r-2 pr-4': typeof p.production_hours == 'number',
                })}
              >
                {p.built_on}
              </div>
              {typeof p.production_hours == 'number' ? (
                <div className='pr-4 text-muted-500'>
                  {numberFormat(p.production_hours) +
                    ' ' +
                    t('products:HOURS_ABBR')}
                </div>
              ) : null}
            </div>
            {isAvailable && (
              <div className='text-right font-secondary text-xl font-medium text-brand-500'>
                {currencyFormat(p.price, locale)}
              </div>
            )}
          </div>
        </div>
      </a>
    </Link>
  );
}

export function SearchBar({
  productsResponse,
}: {
  productsResponse: ApiProductsResponse;
}) {
  return (
    <div className='flex flex-grow'>
      <div className='relative w-full'>
        <AutocompleteSearchBar productsResponse={productsResponse} />
      </div>
    </div>
  );
}
