// CRMRepository.ts
import axios, { AxiosInstance } from 'axios';

const API_URL = process.env.NEXT_PUBLIC_CRM_API_URL;

interface LeadI {
  city: string;
  company: string;
  country: string;
  email: string;
  'first-name': string;
  'last-name': string;
  'phone-number': string;
  'phone-prefix': string;
  'platform-name': string;
  'platform-type': string;
}

interface BuyLead extends LeadI {
  sku: string;
  subject?: string;
  inspection?: boolean;
  finance?: boolean;
  transport?: boolean;
  'on-site-installment'?: boolean;
  'transport-address'?: string;
  offer?: string;
  currency?: string;
  message?: string;
}

interface SellLead extends LeadI {
  brand: string;
  model: string;
  year: string;
  machine_category: string;
  'machine-under-power': '1' | '0'; // API expects a string
  'has-files'?: boolean; // Only sent on the third step
  original_equipment_value?: string;
  production_hours?: string;
  comments?: string;
  gclid?: string;
  'origin-domain': string;
  consent: boolean;
}

interface WantedLead extends LeadI {
  brand: string;
  model: string;
  year: string;
  machine_category: string;
  price_ranges: string;
  comments: string;
}

interface LiquidationProjectLead extends LeadI {
  message: string;
}

// Singleton Axios instance with optional mocking
let axiosInstance: AxiosInstance | null = null;

function getAxiosInstance(): AxiosInstance {
  if (axiosInstance) {
    return axiosInstance;
  }

  axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return axiosInstance;
}

export function saveBuylead(lead: BuyLead) {
  if (!lead['transport']) {
    if (!lead['on-site-installment']) {
      delete lead['transport-address'];
    }
  }
  return getAxiosInstance().post('buylead', lead);
}

export function liquidationprojectlead(lead: LiquidationProjectLead) {
  return getAxiosInstance().post('liquidationprojectlead', lead);
}

export function saveSelllead(lead: SellLead) {
  return getAxiosInstance().post('selllead', lead, {
    headers: {
      Authorization: `Token ${process.env.NEXT_PUBLIC_CRM_API_TOKEN}`,
    },
  });
}

export function updateSelllead(lead: Partial<SellLead>, id: string) {
  return getAxiosInstance().put(`selllead/${id}`, lead, {
    headers: {
      Authorization: `Token ${process.env.NEXT_PUBLIC_CRM_API_TOKEN}`,
    },
  });
}

export function saveWantedlead(lead: WantedLead) {
  return getAxiosInstance().post('wantedlead', lead);
}
